import {
  ChangeEvent, Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import {
  ApiGetPhoneNumber,
  ApiUserPhoneConfirm,
  ApiUserPhoneInfo,
  ApiUserPhoneVerify,
  fetchAbort,
  fetcher, GetPhoneNumberDataApi,
  isApiV1Errors,
  isPhoneNumberConfirmOk,
  isPhoneNumberInfoOk,
  isPhoneNumberVerifyOk,
  isUserGetPhoneNumberOk,
} from '@utils/data-fetching';
import { useTranslation } from 'next-i18next';
import { ExclamationCircleIcon, CheckIcon } from '@heroicons/react/24/solid';
import {
  Button, FormError, Label, RecordInterface,
} from '@components/common/Forms';
import { Anchor } from '@components/common/Anchor';
import { bjToast } from '@components/common';
import { useSwrStaticPhoneCountries } from '@hooks/useSwrApi/endpoints/static/useSwrStaticPhoneCountries';
import { Input } from '../Input/Input';
import { Select } from '../Select/Select';

interface IProps {
  initialValue?: GetPhoneNumberDataApi;
  // if true, it will get the user's phone number from the API and populate the input with it
  toGetDefaultUserPhoneFromApi: boolean;
  record: RecordInterface;
  recordName: string;
  onChangeWithSuccess?: (value: string, object?: GetPhoneNumberDataApi) => void;
  phoneConfirmedStatusCallback?: Dispatch<SetStateAction<boolean>>;
  locale: Locale;
}

/**
 * @description Phone component with country selector and input for phone number
 * * uses the record hook from bjFrom for the input, handles phone number validation
 *
 * @param initialValue - the initial value of the phone number object
 * @param toGetDefaultUserPhoneFromApi - if true, it will get the user's phone number from the API and populate the input with it
 * @param record - the record hook from bjForm
 * @param recordName - the name of the record
 * @param onChangeWithSuccess - callback function that will be called when the phone number is changed and validated
 * @param phoneConfirmedStatusCallback - callback function that will be called when the phone number info is fetched
 * @param locale - the current app locale
 */
export const PhoneInputStandalone = ({
  initialValue, toGetDefaultUserPhoneFromApi, record, recordName, onChangeWithSuccess, locale, phoneConfirmedStatusCallback,
}: IProps) => {
  const { t } = useTranslation('common');

  // list of phone countries
  const { data: phonePrefixes } = useSwrStaticPhoneCountries(locale);

  // inputs country code and phone number
  const [countryCode, setCountryCode] = useState<string>('40');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [countryId, setCountryId] = useState<string | undefined>();

  // country code and phone number
  const [defaultPhoneNumber, setDefaultPhoneNumber] = useState<string>('');

  // full phone number string. e.g: +40712345678
  const [fullPhoneString, setFullPhoneString] = useState<string>('');

  // confirmation phone number variables
  const [confirmationCode, setConfirmationCode] = useState<string>('');
  const [showConfirmationCodeInput, setShowConfirmationCodeInput] = useState<boolean>(false);
  const [isLoadingConfirmButton, setIsLoadingConfirmButton] = useState<boolean>(false);
  const [isLoadingResendingCode, setIsLoadingResendingCode] = useState<boolean>(false);
  const [confirmationErrorMessage, setConfirmationErrorMessage] = useState<string>('');

  // verify phone number variables
  const [showVerifyButton, setShowVerifyButton] = useState<boolean>(false);
  const [isLoadingVerifyButton, setIsLoadingVerifyButton] = useState<boolean>(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>('');

  // duplicate phone number variables
  const [showDuplicateNumber, setShowDuplicateNumber] = useState<boolean>(false);

  // populate the input with the initial value
  useEffect(() => {
    if (!initialValue) {
      return;
    }
    setCountryCode(initialValue.country.data.countryCode);
    setPhoneNumber(initialValue.number);
    setCountryId(initialValue.country.data.regionCode);
    setDefaultPhoneNumber(initialValue.country.data.countryCode + initialValue.number);
  }, [initialValue]);

  // get the user's phone number from the API and populate the input with it
  useEffect(() => {
    if (!toGetDefaultUserPhoneFromApi) return () => {};

    const { controller, timeoutId } = fetchAbort();

    // get value for phone number
    void fetcher<ApiGetPhoneNumber>('/v1/user/phone-number', locale, { externalAbort: { extController: controller, extTimeout: timeoutId } })
      .then((response) => {
        if (isUserGetPhoneNumberOk(response)) {
          setCountryCode(response.data.country.data.countryCode);
          setPhoneNumber(response.data.number);
          setCountryId(response.data.country.data.regionCode);
          setDefaultPhoneNumber(response.data.country.data.countryCode + response.data.number);

          // check if phone number is confirmed by the user
          void fetcher<ApiUserPhoneInfo>('/v1/user-phone/info', locale, { method: 'POST', payload: { number: `+${response.data.country.data.countryCode}${response.data.number}` }, stringifyPayload: true })
            .then((res) => {
              if (isPhoneNumberInfoOk(res)) {
                if (!res.isConfirmed) {
                  setShowVerifyButton(true);
                  if (phoneConfirmedStatusCallback) {
                    phoneConfirmedStatusCallback(false);
                  }
                } else if (phoneConfirmedStatusCallback) {
                  phoneConfirmedStatusCallback(true);
                }
              }
            });
        } else if (phoneConfirmedStatusCallback) {
          phoneConfirmedStatusCallback(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, [locale, toGetDefaultUserPhoneFromApi, phoneConfirmedStatusCallback]);

  // populate full phone string when changing country code or phone number
  useEffect(() => {
    let number = (phoneNumber ?? '').trim().replace(/\s/g, '');
    number = number.substring(0, 1) === '0' ? number.slice(1) : number;
    setFullPhoneString(`+${countryCode ?? ''}${number}`);
  }, [countryCode, phoneNumber]);

  // handle country code change
  const onChangeCountryCode = (e: ChangeEvent<HTMLSelectElement>): string | undefined => {
    e.preventDefault();

    const selectedOption = phonePrefixes?.filter((item) => item.name
      === e.target.value.replace(/[^0-9.]+/g, ''));

    if (!selectedOption) {
      return undefined;
    }

    setCountryCode(selectedOption[0].name ?? '');
    setCountryId(selectedOption[0].id);

    return selectedOption[0].name;
  };

  // handle phone number change or country code change
  const handlePhoneChange = (number?: string, forceCountryCode?: string) => {
    const newCountryCode = forceCountryCode ?? countryCode;

    const phoneVal = (number ?? phoneNumber ?? '').trim()
      .replace(/\s/g, '')
      .replace(/\D/g, '');

    setShowDuplicateNumber(false);
    setShowConfirmationCodeInput(false);

    if (phoneVal?.length >= 7 && phoneVal?.length <= 12 && (newCountryCode + phoneVal !== defaultPhoneNumber)) {
      setShowVerifyButton(true);
    } else {
      setShowVerifyButton(false);
    }
    setPhoneErrorMessage('');
  };

  // verify and claim user phone number
  const onVerifyPhoneNumber = () => {
    const payload = { number: fullPhoneString };
    void fetcher<ApiUserPhoneVerify>('/v1/user-phone/verify', locale, { method: 'POST', payload, stringifyPayload: true })
      .then((response) => {
        if (isPhoneNumberVerifyOk(response)) {
          setShowConfirmationCodeInput(true);
          setShowDuplicateNumber(false);
          bjToast.success(t('account.settings.label.verify_code_sent'));
        }
        if (isApiV1Errors(response)) {
          bjToast.error(response.errors[0].message);
        }
        setIsLoadingVerifyButton(false);
        setShowVerifyButton(false);
        setIsLoadingResendingCode(false);
      });
  };

  // get the status of a new phone number status
  const onGetPhoneStatus = () => {
    setIsLoadingVerifyButton(true);
    void fetcher<ApiUserPhoneInfo>('/v1/user-phone/info', locale, { method: 'POST', payload: { number: fullPhoneString }, stringifyPayload: true })
      .then((response) => {
        if (isPhoneNumberInfoOk(response)) {
          if (!response.isConfirmed) {
            // phone number is not used and send a confirmation code through SMS
            onVerifyPhoneNumber();
            return;
          }

          if (response?.isAlreadyInUse) {
            // phone number is already in use by another user
            setShowDuplicateNumber(true);
          }
        }

        if (isApiV1Errors(response)) {
          setPhoneErrorMessage(response.errors[0].message);
        }
        setIsLoadingVerifyButton(false);
        setShowVerifyButton(false);
      });
  };


  // confirm phone number with code received via SMS and save it
  const onConfirmNumberWithCode = () => {
    setIsLoadingConfirmButton(true);

    const payload = { phoneNumber: { number: fullPhoneString }, code: confirmationCode };

    void fetcher<ApiUserPhoneConfirm>('/v1/user-phone/confirm', locale, { method: 'POST', payload, stringifyPayload: true })
      .then((response) => {
        if (isPhoneNumberConfirmOk(response)) {
          setShowConfirmationCodeInput(false);
          setDefaultPhoneNumber(fullPhoneString.slice(1));
          bjToast.success(t('account-confirm-phone-number-succes'));
          setConfirmationCode('');
          if (onChangeWithSuccess) {
            onChangeWithSuccess(fullPhoneString, { country: { data: { countryCode, regionCode: countryId } }, number: phoneNumber });
          }
          if (phoneConfirmedStatusCallback) {
            phoneConfirmedStatusCallback(true);
          }
        } else {
          setConfirmationErrorMessage(t('phone.number.confirm.failed'));
        }

        setIsLoadingConfirmButton(false);
      });
  };

  return (
    <Label description={t('global.label.phone')} required>
      <div className="flex flex-col" id="confirm-phone">
        <div className="flex">

          {/* country code */}
          <div className="w-40">
            <Select
              required
              onChange={(e:ChangeEvent<HTMLSelectElement>) => {
                const newCountryCode = onChangeCountryCode(e);
                handlePhoneChange(undefined, newCountryCode);
              }}
              value={countryCode}
              className={`rounded-r-none border-r-0 ${phoneErrorMessage.length > 0 ? 'hasError' : ''}`}
            >
              {/* default option until api response is available */}
              {
                !phonePrefixes?.length && countryId && (
                  <option key={countryId} value={countryCode}>
                    {`${countryId} (+${countryCode})`}
                  </option>
                )
              }

              {/* phone prefixes list */}
              {phonePrefixes && phonePrefixes.map((item) => (
                <option key={item.id} value={item.name}>
                  {`${item.id} (+${item.name})`}
                </option>
              ))}
            </Select>
          </div>

          {/* phone number */}
          <div className="w-full">
            <Input
              {...record(recordName, { removeFromSubmit: true })}
              required
              customValidityMessages={{ valueMissing: t('account.settings.error.invalid_phone_nr') }}
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                handlePhoneChange(e.target.value);
              }}
              type="text"
              minLength={7}
              maxLength={12}
              className={`rounded-l-none ${phoneErrorMessage.length > 0 ? 'hasError' : ''}`}
            />
          </div>
        </div>

        {/* inline error message for phone */}
        <div>
          <FormError errorMessage={phoneErrorMessage} isShowing={phoneErrorMessage.length > 0} />
        </div>

        {/* verify button */}
        {showVerifyButton && (
        <div className="mt-4">
          <div
            className="grid w-full scroll-mt-20 grid-cols-3 rounded border border-error bg-error/10 p-3"
          >
            <div className="col-span-2 flex text-ink">
              <ExclamationCircleIcon className="mr-2 size-5 text-error" />
              {t('confirm.phone.user_profile.button')}
            </div>
            <div className="text-end">
              <Button
                styling="solid"
                color="white"
                size="sm"
                rounding="full"
                onClick={onGetPhoneStatus}
                isLoading={isLoadingVerifyButton}
              >
                {t('global.label.send_sms')}
              </Button>
            </div>
          </div>
        </div>
        )}

        {/* duplicate phone */}
        {showDuplicateNumber && (
          <div className="relative mt-2 flex flex-col">
            <p className="text-sm">
              {t('phone.claim.duplicate.number2')}
            </p>
            <Anchor href="user_logout_reset" size="sm" styling="text" symfonyRoute className="!justify-start">
              {t('phone-claim-option-recover')}
            </Anchor>
          </div>
        )}

        {/* confirmation code */}
        {showConfirmationCodeInput && (
          <div className="relative mt-4 flex flex-col">
            <p className="!mb-2">{t('account.confirm.phone.code.info')}</p>

            {/* confirmation code input */}
            <Input maxLength={4} type="text" pattern="\d*" className="mb-4" value={confirmationCode} onChange={(e) => setConfirmationCode(e.target.value)} />
            <div className="flex w-full">

              {/* confirm code button */}
              <Button color="secondary" size="sm" className="flex gap-2" onClick={onConfirmNumberWithCode} isLoading={isLoadingConfirmButton}>
                <CheckIcon className="size-5" />
                <p>{t('global.action.confirm')}</p>
              </Button>
              <div className="flex w-full flex-col text-end">
                <p className="!m-0 text-xs">{t('account.settings.label.no_sms_received')}</p>

                {/* resend sms button */}
                <Button
                  styling="text"
                  className="ml-auto text-xs"
                  isLoading={isLoadingResendingCode}
                  onClick={() => {
                    setIsLoadingResendingCode(true);
                    onVerifyPhoneNumber();
                  }}
                >
                  {t('account.settings.label.resend_sms')}
                </Button>
              </div>
            </div>

            <FormError errorMessage={confirmationErrorMessage} isShowing={confirmationErrorMessage.length > 0} />
          </div>
        )}

      </div>
    </Label>
  );
};
