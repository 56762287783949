import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { CvDetailObject } from '@components/templates/cv/interface/CvDetailInterface';
import { TagCloudItem } from './TagCloudItem';

interface IProps {
  locale: Locale;
  cvDetail?: CvDetailObject
}

/**
 * @description Next jobs tag cloud component.
 * - `cvDetail` CvDetailObject. The CV detail object.
 * - `locale` Locale.
 */
export const NextJobsTagCloud = ({ cvDetail, locale }: IProps) => {
  // Translation
  const { t } = useTranslation('common');

  // Tag cloud data
  const [tagCloud, setTagCloud] = useState<ApiSchema<'TagCloudItem'>[] | undefined>(cvDetail?.tagCloud);

  // Update tag cloud data
  useEffect(() => {
    if (cvDetail?.tagCloud) setTagCloud(cvDetail.tagCloud);
  }, [cvDetail?.tagCloud]);

  // Null if no data available
  if (!tagCloud || tagCloud?.length === 0) return null;

  // Render component
  // ************************
  return (
    <div className="mt-6 border-t border-dashed pt-6">
      {/* Title */}
      <h6 className="mb-4 font-semibold">{t('cv.section.label.tagCloud')}</h6>

      {/* Tag cloud list */}
      <div className="flex flex-wrap items-center justify-start gap-2">
        { tagCloud.map((item) => (
          <TagCloudItem
            key={item.escoId}
            itemData={item}
            tagCloudSetter={setTagCloud}
            locale={locale}
          />
        ))}
      </div>
    </div>
  );
};
