import { useTranslation } from 'next-i18next';
import { Anchor, bjToast, Button } from '@components/common';
import {
  FormError, GooglePlacesInput, Input, Label, Textarea,
} from '@components/common/Forms';
import { useAppDispatch, useAppSelector } from '@store/index';
import React, {
  ChangeEvent,
  FormEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import { useBjForm } from '@hooks/useBjForm';
import {
  ApiResumePersonalData,
  fetchAbort, fetcher, FetcherConfig, GetPhoneNumberDataApi,
  isApiV1Errors, isUserResumePersonalDataOk,
} from '@utils/data-fetching';
import { SocialItemInput } from '@components/templates/profile/personal-data/SocialItemInput';
import { ResumeSocialIconById } from '@components/templates/profile/common/ResumeSocialIconById';
import { BirthdayInput } from '@components/common/DatepickerInput/BirthdayInput';
import { PhoneInputStandalone } from '@components/common/Forms/PhoneInputStandalone/PhoneInputStandalone';
import { FooterButtonsSaveDelete } from '@components/templates/profile/common/FooterButtonsSaveDelete';
import {
  ArrowDownTrayIcon, EnvelopeOpenIcon, EyeIcon,
} from '@heroicons/react/24/outline';
import { showCvModalTalent } from '@components/templates/cv/cv-in-modal/CvModalTalent';
import { isUserWithoutResume } from '@utils/resume/user-resume';
import { useCvDetails } from '@components/templates/cv/hooks';
import { NextJobsTagCloud } from '@components/templates/profile/personal-data/NextJobsTagCloud';

// Interface
// ******************************************
interface PersonalDataFormProps {
  locale: Locale;
  showSocialProfiles?: boolean;
  showPrivacyLevel?: boolean;
  isModalLayout?: boolean;
  onRequestUpdatedData: () => void;
  setIsUploadNewCvModalOpen?: (value: boolean) => void;
  userSlug?: string;
}

/**
 * @description PersonalDataForm. Component that renders a form for editing profile personal data, including social profiles and privacy levels.
 *
 * - **locale** -Locale - The current app locale.
 * - **showSocialProfiles** -boolean - Whether the button is disabled or not.
 * - **showPrivacyLevel** -boolean - The navigation direction.
 * - **onRequestUpdatedData** - function - Callback to execute when the data is successfully updated.
 */
export const PersonalDataForm = (props: PersonalDataFormProps) => {
  // Destructure props
  const {
    locale,
    showSocialProfiles = true,
    showPrivacyLevel = true,
    isModalLayout = false,
    onRequestUpdatedData,
    userSlug,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  const dispatch = useAppDispatch();

  const phoneNumber = useAppSelector((state) => state.userOwnResume.phoneNumber);
  const personalData = useAppSelector((state) => state.userOwnResume.personalData);

  const userResume = useAppSelector((state) => state.userOwnResume.userResume);
  const hasResume = !isUserWithoutResume(userResume);

  // date state for date picker
  const [date, setDate] = useState<Date | null>(null);

  // states for personal links strings, we use inputs with state for these 3
  const [linkedInProfile, setLinkedInProfile] = useState<string>('');
  const [githubProfile, setGithubProfile] = useState<string>('');
  const [professionalProfile, setProfessionalProfile] = useState<string>('');

  const [socialErrorMessage, setSocialErrorMessage] = useState<string>('');
  const [isLinkedInError, setIsLinkedInError] = useState<boolean>(false);
  const [isGithubError, setIsGithubError] = useState<boolean>(false);
  const [isProfessionalError, setIsProfessionalError] = useState<boolean>(false);

  const socialLinkRef = useRef<HTMLDivElement>({} as HTMLDivElement);

  const [privacyLevel, setPrivacyLevel] = useState<number>(1);
  const [gender, setGender] = useState<string>();
  const [aboutMe, setAboutMe] = useState<string>('');
  const [isGenderError, setIsGenderError] = useState<boolean>(false);

  // A flag indicating whether the form was submitted.
  // It is necessary to prevent useEffect hook from executing more than once after form submission.
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const { cvDetail } = useCvDetails({
    slug: userSlug,
    toFetchOperationJob: false,
    locale,
    waitForSlug: true,
  });

  const {
    record, setFormValues, handleSubmit, formStatus, isPosting,
  } = useBjForm({
    submitURL: '/v1/resume/personal-data',
    wizardData: {
      socialLinks: [
        { id: 'linkedin', url: linkedInProfile },
        { id: 'github', url: githubProfile },
        { id: 'professional', url: professionalProfile },
      ],
      details: aboutMe,
    },
  });

  //  populate data based
  useEffect(() => {
    if (personalData) {
      setFormValues(personalData);
      setPrivacyLevel(personalData?.privacyLevel ?? 1);
      if (personalData?.gender) {
        setGender(personalData.gender);
      }
    }
  }, [personalData, setFormValues]);


  const getPersonalData = useCallback((config: FetcherConfig = {}) => {
    void fetcher<ApiResumePersonalData>('/v1/resume/personal-data', locale, { ...config })
      .then((personalDataResponse) => {
        if (isUserResumePersonalDataOk(personalDataResponse)) {
          dispatch({ type: 'userOwnResume/savePersonalData', payload: personalDataResponse });
        }
      });
  }, [locale, dispatch]);

  //  fetch personal data from API
  useEffect(() => {
    const { controller, timeoutId } = fetchAbort();
    if (userSlug) getPersonalData({ externalAbort: { extController: controller, extTimeout: timeoutId } });
    return () => controller?.abort();
  }, [getPersonalData, userSlug]);

  // populate local states with data from API
  useEffect(() => {
    if (personalData) {
      if (personalData.birthDate !== undefined) {
        setDate(new Date(personalData.birthDate));
      }
      if (personalData.socialLinks !== undefined) {
        personalData.socialLinks.forEach((socialLink) => {
          if (socialLink.id === 'linkedin') {
            setLinkedInProfile(socialLink.url);
          }
          if (socialLink.id === 'github') {
            setGithubProfile(socialLink.url);
          }
          if (socialLink.id === 'professional') {
            setProfessionalProfile(socialLink.url);
          }
        });
      }
    }
  }, [personalData, setFormValues]);

  // handles form submission response
  useEffect(() => {
    if (!formSubmitted || isPosting) {
      return;
    }

    // form submitted successfully
    if (formStatus.actionStatus === 'success') {
      onRequestUpdatedData();
      getPersonalData();
      bjToast.success(t('global.action.save_successful'));
      setFormSubmitted(false);
    }

    // form submitted with error
    if (formStatus.actionStatus === 'error' && isApiV1Errors(formStatus.data)) {
      bjToast.error(formStatus.data.errors[0].message);

      // scroll to the social links section if there's an error
      if (formStatus.data.errors[0].path === 'socialLinks' && socialLinkRef.current) {
        setSocialErrorMessage(formStatus.data.errors[0].message);
        socialLinkRef.current.scrollIntoView({ behavior: 'smooth' });
      }

      setFormSubmitted(false);
    }
  }, [formStatus, onRequestUpdatedData, getPersonalData, t, formSubmitted, isPosting]);

  const onPrivacyLevelPick = (level: number) => {
    setFormValues({ privacyLevel: level });
    setPrivacyLevel(level);
  };

  const onGenderPick = (value: string) => {
    setFormValues({ gender: value });
    setGender(value);
    setIsGenderError(false);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    // scroll handled in form
    if (!gender) {
      setIsGenderError(true);
    }

    // check if social links are valid and stop
    if (isProfessionalError || isGithubError || isLinkedInError) {
      e.preventDefault();
      socialLinkRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    handleSubmit(e);
    setFormSubmitted(true);
  };

  const rowWithOneField = 'grid place-items-start';


  // Render component
  // *****************************************************
  return (
    <form noValidate onSubmit={onSubmit}>

      {/* Personal Data */}


      <div className={`${isModalLayout ? 'grid grid-cols-2 gap-4' : rowWithOneField}`}>
        {/* first name */}
        <Label description={t('global.label.first_name')} required>
          <Input type="text" {...record('firstName')} minLength={1} required customValidityMessages={{ valueMissing: t('error.message.empty.firstname') }} />
        </Label>

        {/* last name */}
        <Label description={t('global.label.last_name')} required>
          <Input type="text" {...record('lastName')} minLength={1} required customValidityMessages={{ valueMissing: t('error.message.empty.lastname') }} />
        </Label>
      </div>

      <div className={`${isModalLayout ? 'grid grid-cols-2 gap-4' : rowWithOneField}`}>
        {/* birthday */}
        <div className="flex w-full flex-col">
          <Label description={t('global.label.birth_date')} htmlFor="birthDate" required />
          <BirthdayInput
            {...record('birthDate')}
            customValidityMessages={{ valueMissing: t('msg-error-birthday-requested') }}
            required
            date={date}
            setDate={(val) => {
              setDate(val);
            }}
          />
        </div>


        {/* location */}
        <Label required htmlFor="resumeLocation" description={t('cv.current.location')}>
          <GooglePlacesInput
            record={record}
            recordName="resumeLocation"
            customValidityMessages={{ valueMissing: t('error.message.empty.location') }}
          />
        </Label>
      </div>

      <div className={`${isModalLayout ? 'grid grid-cols-2 gap-4' : rowWithOneField}`}>
        {/* email */}
        <Label description={t('global.label.email')} required>
          <Input type="email" {...record('email')} required customValidityMessages={{ valueMissing: t('error.message.empty.email') }} />
        </Label>

        {/* phone input with confirmation component */}
        <div className="flex w-full">
          <PhoneInputStandalone
            initialValue={phoneNumber}
            toGetDefaultUserPhoneFromApi
            record={record}
            recordName="phone"
            locale={locale}
            onChangeWithSuccess={(phone: string, phoneObject?: GetPhoneNumberDataApi) => {
              dispatch({ type: 'userOwnResume/updateResumePhoneNumber', payload: phone });
              if (phoneObject) {
                dispatch({ type: 'userOwnResume/savePhoneNumber', payload: phoneObject });
              }
            }}
          />
        </div>
      </div>

      {/* gender */}
      <div className="mt-4 w-full sm:w-1/2 sm:pr-7">
        <Label htmlFor="gender" description={t('global.label.gender')} required />
        <div className="relative mt-2 flex w-fit items-center justify-center rounded-full bg-surface-100 p-2">
          {/* male */}
          <Button
            styling={gender === 'M' ? 'solid' : 'outline'}
            onClick={() => onGenderPick('M')}
            color={isGenderError ? 'error' : 'ink'}
            rounding="none"
            className="mr-0.5 rounded-full border-0 !py-2"
          >
            {t('global.gender.male_long')}
          </Button>
          {/* female */}
          <Button
            styling={gender === 'F' ? 'solid' : 'outline'}
            onClick={() => onGenderPick('F')}
            color={isGenderError ? 'error' : 'ink'}
            rounding="none"
            className="mr-0.5 rounded-full border-0 !py-2"
          >
            {t('global.gender.female_long')}
          </Button>
        </div>
        <Input type="text" required hidden {...record('gender')} />
      </div>

      {/* Social profiles */}
      {showSocialProfiles && (
        <div className="mt-6 border-t border-dashed pt-6" ref={socialLinkRef}>
          <h6 className="mb-5 font-semibold">{t('cv.edit.personal_links')}</h6>

          {/* linkedin */}
          <SocialItemInput
            onChange={(e) => {
              setLinkedInProfile(e.target.value);
              setSocialErrorMessage('');
              setIsLinkedInError(!e.target.validity.valid && e.target.value.length > 0);
            }}
            pattern="(.*)linkedin\.com(.*)"
            value={linkedInProfile}
            errorMessage={t('error.message.linkedin_link')}
            placeholder={t('add_linkedin_profile_url')}
            socialName={t('cv.label.social_link.linkedin')}
            socialIcon={ResumeSocialIconById('linkedin', 'w-5 h-5 ')}
          />

          {/* github */}
          <SocialItemInput
            onChange={(e) => {
              setGithubProfile(e.target.value);
              setSocialErrorMessage('');
              setIsGithubError(!e.target.validity.valid && e.target.value.length > 0);
            }}
            pattern="(.*)github\.com(.*)"
            value={githubProfile}
            errorMessage={t('error.message.github_link')}
            placeholder={t('add-github-profile-url')}
            socialName={t('cv.label.social_link.github')}
            socialIcon={ResumeSocialIconById('github', 'w-5 h-5')}
          />

          {/* professional */}
          <SocialItemInput
            onChange={(e) => {
              setProfessionalProfile(e.target.value);
              setSocialErrorMessage('');
              setIsProfessionalError(!e.target.validity.valid && e.target.value.length > 0);
            }}
            pattern="(.*)[A-Za-z0-9]+\.[a-zA-Z]+(.*)"
            value={professionalProfile}
            errorMessage={t('error.message.professional_link')}
            placeholder={t('add_portofolio_url')}
            socialName={t('cv.label.social_link.professional')}
            socialIcon={ResumeSocialIconById('professional', 'w-5 h-5')}
          />

          <FormError errorMessage={socialErrorMessage} isShowing={socialErrorMessage.length > 0} />
        </div>
      )}

      {/* About me */}
      <div className="mt-6 border-t border-dashed pt-6">
        <h6 className="mb-4 font-semibold">{t('cv.edit.about-me')}</h6>
        <Textarea
          onChange={(e:ChangeEvent<HTMLTextAreaElement>) => {
            setAboutMe(e.target.value);
          }}
          rows={6}
          {...record('details')}
        />
      </div>

      {/* Next jobs */}
      <NextJobsTagCloud cvDetail={cvDetail} locale={locale} />

      {/* Privacy Level */}
      {showPrivacyLevel && (
        <div className="mt-6 border-t border-dashed pt-6">
          <h6 className="mb-4 font-semibold">{t('cv.edit.profile.options')}</h6>
          <p>{t('cv.status.data.availability.description')}</p>
          <div className="relative mt-2 flex w-fit items-center justify-center rounded-full bg-surface-100 p-2">
            <Button rounding="none" className="mr-0.5 rounded-full border-0 !py-2" styling={privacyLevel === 1 ? 'solid' : 'outline'} color="ink" onClick={() => onPrivacyLevelPick(1)}>{t('privacy_level.label.visible')}</Button>
            <Button rounding="none" className="mr-0.5 rounded-full border-0 !py-2" styling={privacyLevel === 0 ? 'solid' : 'outline'} color="ink" onClick={() => onPrivacyLevelPick(0)}>{t('privacy_level.label.hidden')}</Button>
          </div>
          <div className="mt-5">
            {
                hasResume && (
                <>
                  {/* upload cv */}

                  {/* send on email */}
                  <Anchor className="flex w-full !justify-start gap-3 py-2" styling="text" color="ink" href="app_cv_send_to" symfonyRoute>
                    <EnvelopeOpenIcon className="size-4 text-ink" />
                    <p className="text-sm">{t('cv.action.send')}</p>
                  </Anchor>

                  {/* download */}
                  <Anchor className="flex w-full !justify-start gap-3 py-2" styling="text" color="ink" href="app_cv_download_self" symfonyRoute>
                    <ArrowDownTrayIcon className="size-4 text-ink" />
                    <p className="text-sm">{t('cv.action.pdf')}</p>
                  </Anchor>

                  {/* view cv */}
                  <Button className="flex w-full !justify-start gap-3 py-2" styling="text" color="ink" onClick={() => showCvModalTalent(userResume?.slug ?? '', undefined, true)}>
                    <EyeIcon className="size-4 text-ink" />
                    <span className="text-sm">{t('resume.options.view.candidate')}</span>
                  </Button>


                </>
                )
            }
            {
                !hasResume && (
                <>
                  {/* send on email */}
                  <Button className="flex w-full !justify-start  gap-3 py-2" styling="text" color="ink" disabled>
                    <EnvelopeOpenIcon className="size-4 text-ink" />
                    <p className="text-sm">{t('cv.action.send')}</p>
                  </Button>

                  {/* download */}
                  <Button className="flex w-full !justify-start gap-3 py-2" styling="text" color="ink" disabled>
                    <ArrowDownTrayIcon className="size-4 text-ink" />
                    <p className="text-sm">{t('cv.action.pdf')}</p>
                  </Button>

                  {/* view cv */}
                  <Button className="flex w-full !justify-start gap-3 py-2" styling="text" color="ink" disabled>
                    <EyeIcon className="size-4 text-ink" />
                    <p className="text-sm">{t('resume.options.view.candidate')}</p>
                  </Button>
                </>
                )
            }
          </div>
          <Input type="number" hidden {...record('privacyLevel', { convertValue: 'toNumber', convertEmptyValue: 'toNumber' })} />
        </div>
      )}


      {/* Submit Button */}
      <FooterButtonsSaveDelete isSaveLoading={isPosting} className="fixed inset-x-0 bottom-13 z-1 !mt-12 border-b bg-surface p-4 md:relative md:bottom-auto md:!mt-6 md:border-none md:p-0" />
    </form>
  );
};
