import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Button } from '@components/common';
import { useSwrResumeTagCloudDelete } from '@hooks/useSwrApi/endpoints/resume/useSwrResumeTagCloudDelete';
import { Dispatch, SetStateAction } from 'react';

/** Props */
interface TagCloudItemProps {
  itemData: ApiSchema<'TagCloudItem'>;
  tagCloudSetter: Dispatch<SetStateAction<ApiSchema<'TagCloudItem'>[] | undefined>>;
  locale: Locale;
}

/**
 * @description Tag cloud item component.
 * - `itemData` ApiSchema<'TagCloudItem'>. The tag cloud item data.
 * - `tagCloudSetter` Dispatch<SetStateAction<ApiSchema<'TagCloudItem'>[] | undefined>. The tag cloud setter.
 * - `locale` Locale. The locale to use for the request.
 */
export const TagCloudItem = (props: TagCloudItemProps) => {
  // Destructure props
  const {
    itemData,
    tagCloudSetter,
    locale,
  } = props;

  // Destructure itemData
  const {
    escoId = '',
    label,
  } = itemData;

  // SWR mutation hook
  const { trigger, isMutating } = useSwrResumeTagCloudDelete({
    escoId,
    locale,
    successCallback: () => {
      tagCloudSetter(
        (prevTagCloud) => prevTagCloud
          ?.filter((tag) => tag.escoId !== escoId),
      );
    },
  });

  // Render null if no data available
  if (!label && escoId) return null;

  // Render component
  // *************************
  return (
    <Button
      size="sm"
      rounding="full"
      styling="soft"
      color="secondary"
      className="group gap-1.5 !px-2 !py-1.5 !font-normal !text-left"
      isLoading={isMutating}
    >
      {!isMutating && <CheckIcon className="size-5" />}
      <span className="text-ink group-hover:text-surface">{label}</span>
      <span
        role="presentation"
        className="ml-2 flex size-5 items-center justify-center rounded-full bg-white text-dark"
        onClick={() => { void trigger(); }}
      >
        <XMarkIcon className="size-4 text-dark hover:text-error" />
      </span>
    </Button>
  );
};
