import { format, subYears } from 'date-fns';
import DatePicker from 'react-datepicker';
import React, { forwardRef, useEffect, useRef } from 'react';
import { FormBirthdayPickerInterface, FormElementRefImperative } from '@type/form-types';
import { useSingleFormElement } from '@hooks/useBjForm';
import { FormError } from '@components/common/Forms';
import { IconCalendar } from '@components/common/Forms/DatePickerInput/IconsDatePickerInput';

const maxDate = subYears(new Date(), 15);
const minDate = subYears(new Date(), 80);

/**
 * @description Date picker for choosing the birthday
 * * The use will choose first the year, then the month and then the day
 *
 * @example
 * const [date, setDate] = useState<Date|null>(null)
 * ...
 * <BirthdayInput date={date} setDate={setDate}/>
 *
 * @param date
 * @param setDate
 */
export const BirthdayInput = forwardRef<FormElementRefImperative, FormBirthdayPickerInterface>((props, ref) => {
  // component props
  const {
    // picker props
    date,
    setDate,

    // input props
    className = '',
    required,
    withError = true,
    placeholder = '',
    patternMismatchMessage = '',
    customValidityMessages,
    ...attributes
  } = props;

  // form elements shared logic hook
  const {
    setElementValue, checkElementValidity, elementRef, isInvalid, errorMSG, errorClass,
  } = useSingleFormElement({
    ref,
    patternMismatchMessage,
    customValidityMessages,
    className,
  });

  const yearPickerRef = useRef<DatePicker>({} as DatePicker);
  const monthPickerRef = useRef<DatePicker>({} as DatePicker);
  const dayPickerRef = useRef<DatePicker>({} as DatePicker);

  const dateFormat = 'dd.MM.yyyy';

  // set the input value when the date changes
  useEffect(() => {
    setElementValue(date ? format(date, dateFormat) : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const onChangeYear = (newDate: Date | null) => {
    setDate(newDate);
    monthPickerRef.current.setOpen(true);
  };

  const onChangeMonth = (newDate: Date | null) => {
    setDate(newDate);
    dayPickerRef.current?.setOpen(true);
  };

  const onChangeDay = (newDate: Date | null) => {
    setDate(newDate);
    dayPickerRef.current?.setOpen(false);
  };

  const onOpenPicker = () => {
    yearPickerRef.current?.setOpen(true);
  };

  return (
    <div>
      <div className="relative" onClick={onOpenPicker} role="presentation">
        {/* calendar icon */}
        <div className="absolute w-full h-full flex justify-end">
          <div className="h-full flex flex-col justify-center pr-4">
            <IconCalendar />
          </div>
        </div>
        <input
          className={`pl-3 pr-10 ${className}${isInvalid ? errorClass : ''}`}
          ref={elementRef}
          placeholder={placeholder}
          required={required}
          type="text"
          {...attributes}
        />
      </div>
      { // input's error field - show only if input is required and withError is true (default true)
        required && withError && <FormError errorMessage={errorMSG} isShowing={isInvalid} />
      }

      <div className="[&>div]:flex relative w-full">
        <DatePicker
          showYearPicker
          dateFormat="yyyy"
          selected={date}
          minDate={minDate}
          maxDate={maxDate}
          showPopperArrow={false}
          onChange={(newDate) => onChangeYear(newDate)}
          customInput={<span />}
          ref={yearPickerRef}
          onClickOutside={() => {
            checkElementValidity();
          }}
        />

        <DatePicker
          showMonthYearPicker
          showPopperArrow={false}
          dateFormat="yyyy/MM"
          selected={date}
          onChange={(newDate) => onChangeMonth(newDate)}
          customInput={<span />}
          ref={monthPickerRef}
        />

        <DatePicker
          dateFormat="yyyy/MM/dd"
          showPopperArrow={false}
          selected={date}
          onChange={(newDate) => onChangeDay(newDate)}
          customInput={<span />}
          ref={dayPickerRef}
        />
      </div>
    </div>
  );
});
