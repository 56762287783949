import { Input } from '@components/common/Forms';
import { ChangeEvent, ReactNode } from 'react';

interface IProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  socialName: string;
  socialIcon: ReactNode;
  errorMessage?: string;
  pattern?: string;
}

/**
 * @description Component used for adding social links for: github, linkedin and professional website
 * @param value - input value
 * @param onChange - setter for the input field
 * @param placeholder
 * @param socialName - social name
 * @param socialIcon - social icon components
 * @param errorMessage - error message
 * @param pattern - regex pattern
 * @constructor
 */
export const SocialItemInput = ({
  value, onChange, placeholder, socialName, socialIcon, errorMessage, pattern,
}: IProps) => {
  const props = pattern ? { pattern } : {};

  return (
    <div className="mb-4">
      <div className="flex flex-wrap sm:flex-nowrap">
        <div className="w-full order-2 sm:w-3/4 sm:order-1">
          <Input
            type="url"
            className="text-sm"
            required={value?.length > 0}
            patternMismatchMessage={errorMessage}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onChange(e);
            }}
            {...props}
          />
        </div>
        <div className="w-full order-1 flex items-center mb-2 sm:w-1/4 sm:order-2 sm:pl-4 sm:mb-0">
          <div className="bg-surface-100 flex items-center justify-center w-10 h-10 rounded-full">
            {socialIcon}
          </div>
          <div className="hidden">
            {socialName}
          </div>
        </div>
      </div>
    </div>
  );
};
