import { ApiHeaderInfoOk, ApiOwnResumeDetailOk, IPersonalLink } from '@type/v1-api-types';

/**
 * Check if user has a resume. (e.g. after register, user has no resume)
 * @param user
 */
export const isUserWithoutResume = (user: ApiOwnResumeDetailOk | undefined): boolean => !user?.hasResume;


/**
 * @description Sort user resume personal links in the order of sortIds
 * @param personalLinks
 */
export const sortUserResumePersonalLinks = (personalLinks: IPersonalLink[]) => {
  const sortIds = ['linkedin', 'github', 'professional'];

  const sortedLinks: IPersonalLink[] = [];
  sortIds.forEach((id) => {
    const newLink = personalLinks.find((link) => (link.id === id));
    if (newLink) {
      sortedLinks.push(newLink);
    }
  });

  return sortedLinks;
};


/**
 * @description Sync header info from resume and return the new header info if it changed
 * @param resume
 * @param headerInfo
 */
export const syncHeaderInfoFromResume = (resume: ApiOwnResumeDetailOk, headerInfo: ApiHeaderInfoOk | undefined): ApiHeaderInfoOk | undefined => {
  if (headerInfo?.role !== 'USER' || !resume) {
    return undefined;
  }

  let newHeaderInfo: ApiHeaderInfoOk | undefined;

  // cv score changed
  if (resume.cvCompletion && resume.cvCompletion !== headerInfo.resumeScore) {
    newHeaderInfo = { ...headerInfo, resumeScore: resume.cvCompletion };
  }

  // image url changed
  if (resume.imgUrl && resume.imgUrl !== headerInfo?.profileImg) {
    newHeaderInfo = { ...(newHeaderInfo ?? headerInfo), profileImg: resume.imgUrl };
  }

  // email changed
  if (resume.privateInformation?.email && resume.privateInformation.email !== headerInfo?.email) {
    newHeaderInfo = { ...(newHeaderInfo ?? headerInfo), email: resume.privateInformation.email };
  }

  // name changed
  if (resume.privateInformation?.name && resume.privateInformation.name !== headerInfo.name) {
    newHeaderInfo = { ...(newHeaderInfo ?? headerInfo), name: resume.privateInformation.name };
  }

  return newHeaderInfo;
};
