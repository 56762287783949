/**
 * @description Get file extension based on filename
 * @param filename
 *
 * @example
 * getFileExtension('file.pdf') // returns 'pdf'
 * getFileExtension('file') // returns ''
 */
export const getFileExtension = (filename: string): string => {
  if (!filename || !filename.includes('.')) return '';

  return filename.slice((filename.lastIndexOf('.') + 1)).toLowerCase();
};


// list of accepted file extensions
export const ACCEPTED_FILE_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.doc',
  '.docx',
  '.rtf',
  '.pdf',
];

// list of accepted mime types for file upload
export const ACCEPTED_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/pjpeg',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/rtf',
  'application/x-rtf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/octet-stream',
  'text/rtf',
  'text/richtext',
];
