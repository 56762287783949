// *********************************************************************************************
// pathname: /v2/resume/tag-cloud/{escoId}
// *********************************************************************************************
import { useSwrMutationApi } from '@hooks/useSwrApi';

/** Props */
interface UseSwrResumeTagCloudDeleteProps {
  escoId: string;
  locale: string;
  successCallback?: () => void;
}

/**
 * @description SWR mutation: DELETE remove tag from resume.
 * - `escoId` string. The esco id.
 * - `locale` Locale. The locale to use for the request. Default: 'ro'.
 * - `successCallback` function. The callback to call on success.
 */
export const useSwrResumeTagCloudDelete = (props: UseSwrResumeTagCloudDeleteProps) => {
  // Destructure props
  const { escoId, locale, successCallback } = props;

  // SWR mutation hook
  return useSwrMutationApi<object, never>({
    url: `/v2/resume/tag-cloud/${escoId}`,
    locale,
    fetcherConfig: {
      method: 'DELETE',
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) successCallback();
      },
    },
    toastsOptions: {
      showErrorToast: true,
      showSuccessToast: true,
    },
  });
};
